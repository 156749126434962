@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,700');
@import "modules/responsive-type.scss";

$primary: #ffcc00;
/* MAIN COLOR */
$secondary: #ffcc00;
/* SECONDARY COLOR */
$gray: #666;
/* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$blk: #333;
/* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$footerLinks: #fff;

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #0065dd;
  -webkit-text-fill-color: #0065dd;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

// Set your maximum and minimum screen sizes.
$min_width: 320px;
$max_width: 1920px;

// Font sizes will range between the $min_font and $max_font.
$min_font: 15px;
$max_font: 22px;

// Change these values to change the "scale" between different headers
// (h1,h2,h3, etc.). Larger numbers = larger font-sizes. 

$mod_1: 1.0; // mobile
$mod_2: 1.3; // desktop

html {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

p {
  font-size: 1rem;
}

h1 {
  font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem;
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}

h2 {
  font-size: $mod_1*$mod_1*$mod_1 *1rem;
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font, $mod_2*$mod_2*$mod_2 *$min_font);
}

h3 {
  font-size: $mod_1*$mod_1 *1rem;
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_2*$mod_2 *$min_font);
}


html,
body {
  height: 100%;

  /*margin: 0;
  padding: 0;
  overflow: auto;*/
}

body {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  font-family: 'PT Sans',
    sans-serif;
  font-weight: normal;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {}
}

.flash {
  display: none;
}

q,
blockquote {
  quotes: "“""”";
}

.navbarFixed {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #0065dd;
  -webkit-text-fill-color: #0065dd;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}


nav.navbar {
  z-index: 1000;
  border: none;

  .navbar-nav {

    >li>a {
      text-align: center;
      margin-top: 22px;
      display: flex;
      align-items: center;
      color: $blk;
   
      text-transform: uppercase;

      @media (max-width: 767px) {
        margin-top: 0;
        padding: 6px 20px;
        display: inline-block;
      }

      &:hover {
        background: $primary;
        color: $wht;

        @media (max-width: 1024px) {
          background: transparent;
          color: $blk;
          outline: 0;
        }
      }

      &:focus,
      &:active {
        background: transparent;
        color: $blk;
        outline: 0;
      }
    }
  }
}

.navbar-right {
  margin-top: 0px;
}

.navbar-toggle {
  margin: 20px 15px 8px 0px;
  border: 1px solid transparent;
  border-radius: 4px;
}


/** LOGIN FORM **/
@mixin btn {
  border: none;
  background: $primary;
  color: $wht;
  padding: 1em 2em;
  font-size: 1em;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  margin: 1em 0em;
  transition: 0.3s ease all;
  text-decoration: none;
  outline: 0;

  @media(max-width: 767px) {
    font-size: .7em;
  }

  &:hover {
    background: $secondary;
    color: $wht;
    text-decoration: none;
    outline: 0;

    @media (max-width: 1024px) {
      background: $primary;
      color: $wht;
      text-decoration: none;
      outline: 0;
    }
  }

  &:focus,
  &:active {
    background: $primary;
    color: $wht;
    text-decoration: none !important;
  }
}

.btn-default {
  @include btn;
}

// mixins end here


// sections start
section {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 80px 0;

  @media(max-width: 991px) {
    padding: 60px 0;
  }

  @media(max-width: 767px) {
    padding: 40px 0;
  }

}

.section {
  padding: 150px 0;
  background-color: lighten($primary, 35%);
  background-image: linear-gradient(rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.2)), url('../img/bg1.jpg');
  background-attachment: fixed;

  @media(max-width: 1024px) {
    background-attachment: scroll;
  }

  @media(max-width: 991px) {
    padding: 100px 0;
  }

  @media(max-width: 767px) {
    padding: 80px 0;
  }

  @media(max-width:450px) {
    padding: 30px 0;
  }

  h1 {
    color: $wht;
    font-weight: bold;
  }
}

.section-a {
  color: $blk;
  background-color: $wht;

  h2 {
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: bold;
  }

  .box {
    padding: 2px;
  }

  img {
    @media (max-width: 991px) {
      max-width: 400px;
      width: 100%;
    }
  }

}

.section-b {
  background-color: $primary;
  padding: 150px 0;
  color: $blk;

  @media(max-width: 991px) {
    padding: 120px 0;
  }

  @media(max-width: 767px) {
    padding: 80px 0;
  }

  h2 {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
  }

}

.section-c {
  h2 {
    margin-top: 20px;
    text-transform: uppercase;
    font-weight: bold;
    padding: 0 25px 10px 25px;
    color: $primary;
    border-bottom: 2px solid $primary;
  }

  p {
    padding: 0 5px;
  }

  img {
    margin-bottom: 15px;
  }

  .box {
    padding: 2px;
    margin-top: 30px;
  }

  img {
    @media (max-width: 991px) {
      max-width: 400px;
      width: 100%;
    }
  }
}

.section-d {
  color: $blk;
  background-color: $wht;

  h3 {
    text-transform: uppercase;
    font-weight: bold;
  }

  @media(max-width: 480px) {
    text-align: center;
  }

  i {
    @media(max-width: 480px) {
      text-align: center;
    }
  }

  a {
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: none;
      outline: 0;
    }
  }

  .footer-banner {
    border-left: 10px solid $blk;

    @media (max-width: 991px) {
      border: none;
    }
  }


  ul {
    padding: 0;

    li {
      line-height: 2em;
      list-style: none;
      @include fluid-type($min_width, $max_width, 15px, 17px);

      @media(max-width: 480px) {
        text-align: center;
      }

      i {
        padding-right: 10px;
      }

      a {
        color: $blk;
        text-align: left;
        @include fluid-type($min_width, $max_width, 15px, 17px);
      }
    }
  }
}

// sections end


.modal-dialog {
  max-width: 500px;
  width: 100%;
  text-align: center;
  margin: 6em auto;

  .close {
    display: none;
  }

  .modal-content {
    color: $primary;

    h2 {
      text-align: center;
    }
  }

  a.btn,
  a.btn-default,
  button {
    @include btn margin: 0;
    display: block;
  }
}

.modal-header,
.modal-footer {
  background: $primary;
  color: $wht;
}

.modal-open {
  overflow: auto;
  padding-right: 0px !important;
}


footer {
  padding: 50px 0px 20px;
  background: $primary;
  color: $blk;

  
  p {
    font-size: .7rem;
    text-transform: uppercase;
    @media (max-width: 767px) {
      margin: 0;
    }
  }

  a {
    color: $footerLinks;
    white-space: nowrap;
    

    &:hover {
      color: lighten($footerLinks, 10%);
      outline: 0 !important;
      text-decoration: none;
    }

    &:focus {
      color: $footerLinks;
      outline: 0 !important;
      text-decoration: none;
    }
  }

}

select {
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
  outline: 0 !important;
}

.logo {
  max-height:100px;
  padding: 0.5em;
}

@media(max-width: 767px) {
  .logo {
    max-height: 70px;
  }

  .navbar-header a {
    float: left;
  }
}


.top-pad {
  padding: 0.5em;
  background: white;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1024px) {
    display: block;
    /* may need to be inline-block */
  }
}

.navbar-default {
  background: white;
}

.visible-500 {
  display: none;

  @media (max-width: 500px) {
    display: block;
  }
}

.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
  background: transparent;
}